<template>
  <div class="frequency-detail">
    <van-row>
      <div class="baseInfo">
        出差细节看板
      </div>
    </van-row>
    <frequency-progress v-for="item in list" v-bind:key="item.id" :data="item"/>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import FrequencyProgress from "@/components/FrequencyProgress/index";

export default {
  name: "FrequencyDetail",
  components: {CrmTabBar, FrequencyProgress},
  data() {
    return {
      list: [
        {
          id: Math.random(),
          title: '哈尔滨拜访',
          date: '2022/12/12',
          user: '李阳',
          status: '未完成',
          to: 'BulkCrmsVisitReplayDetail',
          index: '1',
        },
        {
          id: Math.random(),
          title: '上海客户走访',
          date: '2022/01/12',
          user: '张航',
          status: '未完成',
          to: 'BulkCrmsVisitReplayDetail',
          index: 'final',
        }
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
  }
}
</script>

<style scoped>
.frequency-detail .baseInfo {
  padding: 10px 2px;
  font-size: 25px;
  background: #EBF8FD;
}
.company-info-bk {
  width: 100%;
  height: 150px;
}
</style>